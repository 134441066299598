<div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-primary-subtle">
                        <div class="row">
                            <div class="col-8 d-flex align-items-center">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary"> New Password</h5>
                                    <!-- <p>Reset Password with Skote.</p> -->
                                </div>
                            </div>
                            <div class="col-4 align-self-end">
                                <div class="d-flex justify-content-center">
                                    <img src="assets/images/logo.png" alt="" class="img-fluid w-60 mt-1">
                                </div>
                                <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div>
                            <a routerLink="/">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img src="assets/images/logo-mini.png" alt="" class="rounded-circle"
                                            height="35">
                                    </span>
                                </div>
                            </a>
                        </div>

                        <div class="p-2">
                            <form class="needs-validation" [formGroup]="newpasswordForm" novalidate>
                                <div class="mb-3">
                                    <label for="password">New Password</label>
                                    <div class="input-group auth-pass-inputgroup">
                                        <input [type]="fieldPwdTextType ? 'text' : 'password'"
                                            formControlName="password" class="form-control"
                                            placeholder="Enter new password" (input)="onPasswordInput()">

                                        <button class="btn btn-light ms-0" (click)="togglePwdFieldTextType()"
                                            type="button" id="password-addon">
                                            <i class="mdi mdi-eye-outline"
                                                [ngClass]="{'mdi-eye-off-outline': !fieldPwdTextType, 'mdi-eye-outline': fieldPwdTextType}"></i></button>

                                                <div class="password-requirements-popup" *ngIf="showPasswordRequirements">
                                                    <ul class="password-requirements">
                                                        <li [class.valid]="hasMinLength(newpasswordForm.get('password')?.value || '')">
                                                          At least {{ policy.MinLength }} characters
                                                        </li>
                                                        <li *ngIf="policy.RequiresUpperCase" [class.valid]="hasUppercaseLetter(newpasswordForm.get('password')?.value || '')">
                                                          At least 1 uppercase letter
                                                        </li>
                                                        <li *ngIf="policy.RequiresLowerCase" [class.valid]="hasLowercaseLetter(newpasswordForm.get('password')?.value || '')">
                                                          At least 1 lowercase letter
                                                        </li>
                                                        <li *ngIf="policy.RequiresSpecialChar" [class.valid]="hasSpecialCharacter(newpasswordForm.get('password')?.value || '')">
                                                          Special character ({{ policy.SpecialCharacters }})
                                                        </li>
                                                        <li *ngIf="policy.RequiresNumber" [class.valid]="hasNumber(newpasswordForm.get('password')?.value || '')">
                                                          At least 1 number
                                                        </li>
                                                      </ul>
                                                  </div>
                                        @if(submitted && f['password'].errors){
                                        <div class="invalid-feedback">
                                            @if(f['password'].errors['required']){
                                            <span>{{resetPwdValidations.newpwd_required_validation}}</span>
                                            }
                                            @if(f['password'].errors['pattern']) {
                                            <div>Invalid Formate</div>}
                                        </div>}
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="password">Confirm Password</label>
                                    <div class="input-group auth-pass-inputgroup">
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                            formControlName="confirmPassword" placeholder="Enter confirm password">

                                        <button class="btn btn-light ms-0" (click)="toggleFieldTextType()" type="button"
                                            id="password-addon">
                                            <i class="mdi mdi-eye-outline"
                                                [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType}"></i></button>
                                        @if(submitted && f['confirmPassword'].errors){
                                        <div class="invalid-feedback">
                                            @if(f['confirmPassword'].errors['required']){
                                            <div>{{resetPwdValidations.confirmPwd_required_validation}}</div>
                                            }
                                            @if(f['confirmPassword'].value != f['password'].value){
                                            <div>{{resetPwdValidations.matchPwd_validation}}</div>
                                            }
                                            @if(f['confirmPassword'].errors['pattern']) {
                                            <div>{{regexValidationMsz}}</div>}

                                        </div>}
                                        <!-- <div class="invalid-feedback">
                                            <span
                                                *ngIf="submitted && f['confirmPassword'].value != f['password'].value">{{resetPwdValidations.matchPwd_validation}}</span>
                                        </div> -->

                                    </div>
                                </div>




                            </form>
                            <div class="modal-footer">
                                <!-- <div class="alert alert-danger text-center mb-4 p-2 mt-3" *ngIf="otpErrorMsg" role="alert">
                                    {{respMessage}}
                                </div> -->
                                <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                                <button type="button" class="btn btn-primary waves-effect waves-light"
                                    (click)="onSubmit()">Save</button>
                            </div>
                        </div>

                    </div> <!-- end card-body-->
                </div>


            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->