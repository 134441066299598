import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
  login,
  sendOtp,
} from '../../../store/Authentication/authentication.actions';
import { FirebaseApp } from '@angular/fire/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import {
  getError,
  getLoading,
  getSendOtpError,
  getSendOtpResponse,
} from '../../../store/Authentication/authentication-selector';
import { log } from 'console';
import { LoaderService } from '../../services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import { EncryptionService } from '../../services/encryption.service';
import { resetLogin } from '../../../store/Authentication/authentication.actions';

@Component({
  selector: 'app-rp-login',
  templateUrl: './rp-login.component.html',
  styleUrl: './rp-login.component.scss',
})
export class RpLoginComponent {
  loginForm!: UntypedFormGroup;
  submitted: any = false;
  error: any = '';
  returnUrl!: string;
  fieldTextType!: boolean;

  passwordField: boolean = true;
  otpField: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  error$: Observable<any> | undefined;
  sendOtpResponse$: Observable<unknown> | undefined;
  sendOtpError$: Observable<unknown> | undefined;
  otpResponse: any;
  loginResponse: any;
  loading: boolean = false; // Add loading property
  loader$: Observable<boolean> | undefined;
  token: any;

  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  loginValidations = Validations.LoginPage; // Assuming LoginPage is the key for login validations

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private messaging2: AngularFireMessaging,
    private firebaseApp: FirebaseApp,
    private loaderService: LoaderService,
    private encryptionService: EncryptionService
  ) {
    // const messaging = getMessaging();
    // alert((isPlatformBrowser(this.platformId)) )
    if (isPlatformBrowser(this.platformId)) {
      this.initMessaging();
    }
    this.getStoreInfo();
  }

  getStoreInfo() {
    this.sendOtpResponse$ = this.store.pipe(select(getSendOtpResponse));
    this.sendOtpError$ = this.store.pipe(select(getSendOtpError));
    this.error$ = this.store.pipe(select(getError));
    this.loader$ = this.store.pipe(select(getLoading));

    //  console.log(this.sendOtpResponse$);

    this.sendOtpResponse$.subscribe((res: any) => {
      if (res && res.message == 'Incorrect email.' && res.statusCode == 200) {
        this.otpErrorMsg = true;
        this.otpSuccessMsg = false;
      } else if (res != null) {
        this.otpSuccessMsg = true;
        setTimeout(() => {
          this.otpSuccessMsg = false;
        }, 3000);
        this.otpErrorMsg = false;
      }
      this.otpResponse = res;

      //console.log(this.otpResponse);
    });

    this.sendOtpError$.subscribe((res: any) => {
      if (res != null) {
        this.otpErrorMsg = true;
        this.otpSuccessMsg = false;
        //console.log(this.otpResponse);
      }
    });

    this.error$.subscribe((res: any) => {
      this.loginResponse = res;
      // console.log(this.loginResponse);
    });

    this.loader$.subscribe((state: any) => {
      this.loaderService.setLoading(false);
    });
  }

  initMessaging() {
    const messaging = getMessaging(this.firebaseApp);
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          // console.log('Token:', currentToken);
          this.token = currentToken;
          localStorage.setItem('deviceId', this.token)
          // Send the token to your server and update the UI if necessary
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
          // Prompt the user to grant permission for push notifications
          // You may also want to handle the case where permission is denied
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token:', err);
        // Customize error handling based on the specific error
        // For example, check if the error is related to Service Worker or other issues
      });
  }


  enableOTP(event: any) {
    let otp = event.target.checked;
    // this.loginForm.reset();

    // this.loginForm.controls['otp_password'].setValue('');
    // Reset form control explicitly
    ///console.log(this.loginForm.value);
    if (otp) {
      this.loginForm.get('otp_password')?.reset();
      this.otpField = true;
      this.passwordField = false;
      this.f['otp_password'].setValidators([Validators.required, Validators.pattern(this.digitPattern), Validators.maxLength(6)]);
      this.f['otp_password'].updateValueAndValidity();
      this.f['password'].clearValidators();
      this.f['password'].updateValueAndValidity();
    } else {
      this.loginForm.get('password')?.reset();
      this.f['password'].setValidators(Validators.required);
      this.f['password'].updateValueAndValidity();
      this.f['otp_password'].clearValidators();
      this.f['otp_password'].updateValueAndValidity();
      this.otpField = false;
      this.passwordField = true;
    }
  }

  digitPattern = /^[0-9]+$/;
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      otp_password: ['',],

    });
  }

  ngOnDestroy() {
    this.store.dispatch(resetLogin());
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      const email = this.f['email'].value; // Get the username from the form
      // const password = this.encryptionService.encryptData(
      //   this.f['password'].value
      // ); // Get the password from the form
      const password = this.otpField == true ? this.f['otp_password'].value : this.f['password'].value; // Get the password from the form
      // console.log(this.f['password'].value)
      // console.log(password)

      // console.log(this.otpField) // Login Api
      this.store.dispatch(
        login({
          userInput: email,
          password: password,
          deviceId: this.token,
          loginThroughOtp: this.otpField,
        })
      );
      //this.loginForm.reset();
      // this.submitted = false;
    }
  }

  /**
   * send Otp
   */
  sendOtp() {
    //this.submitted = true;
    if (this.f['email'].value) {
      this.loaderService.setLoading(true);
      this.store.dispatch(sendOtp({ userInput: this.f['email'].value }));
    } else {
    }
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
