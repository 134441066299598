import { Injectable } from '@angular/core';
import JSEncrypt from 'jsencrypt';
@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  publicKey = `-----BEGIN PUBLIC KEY-----
MIIBCgKCAQEA4Jdfm1fgJc4S0JADPxv5Ha5IP2ImXPkSB1C83tBSDv42+TZo8Fc/iAifLOj2nnm8BU9TQTJv4BXV6I+r9SGXRmLEHWN2dh1hrfePedXQ9GFDi88KOGuV4kr+nehcrdGGjWslxT6iINhwufvO79lRSqbhOqcuuGvF8SUO1CUyW9HfLqO+23K5wL5L/7t4nbLp9X873+tGNVG84Pn7xQ0VHRHDWeJvjgEaMEmaug+cg6d4Fbuhg8G3OViEqEw7dePfRq2B2gJD00xknDSiUvR3FWbrS+9vQcOrC5WTd1Xsjaq8jLutRKPZhd6Q3MhkClBPmvYPO5vuBpZqUbUdxhDpFQIDAQAB
-----END PUBLIC KEY-----`;

  private privateKey =
    'MIIEowIBAAKCAQEA4Jdfm1fgJc4S0JADPxv5Ha5IP2ImXPkSB1C83tBSDv42+TZo8Fc/iAifLOj2nnm8BU9TQTJv4BXV6I+r9SGXRmLEHWN2dh1hrfePedXQ9GFDi88KOGuV4kr+nehcrdGGjWslxT6iINhwufvO79lRSqbhOqcuuGvF8SUO1CUyW9HfLqO+23K5wL5L/7t4nbLp9X873+tGNVG84Pn7xQ0VHRHDWeJvjgEaMEmaug+cg6d4Fbuhg8G3OViEqEw7dePfRq2B2gJD00xknDSiUvR3FWbrS+9vQcOrC5WTd1Xsjaq8jLutRKPZhd6Q3MhkClBPmvYPO5vuBpZqUbUdxhDpFQIDAQABAoIBAD84XSFNSztZFtK+tys8pJNGS/ALoODq+7gzcfcGjRLLXI4qUr/Uz31rb+MkcMeLXPHhsn3XxMY/X0dnqfAG1J5rjuj25pG5E5X06d5R4c/o0Sq5zRa0sixRD/tDPVNwd3+3eZpcVycHxjOUPlwlwExTTXNFPFGNhNqso8/z6IkPs68voxRRtdxnLJ192dOYpNuU3sqcOAgDb/Clgka3PCx7as7pTQd7brJezJXnAt07C7lnWCn0ckNEXo4g9pOLF1oTEZWBibEF2jdjlGyfCZ/f7z0EbVjf2tLo58GIm9WpnX2EqlQJYkyVd/KKoT7RCz6dNKh2CC8P92BCz5dr7MECgYEA5Np2aQcC/T2JxN2uDoqkt0oNHgnlfM4jCr/s1YHQcG4uODbKcPt5/X/O7cDYQoTF8qLS/PeIQADmimZKN+4sWQFLtR/NZGzkrpSxbkIL406mjALd2NAwDo+Os/3jiZ47iPEPEuYm+EyB3h6rtG27TAwVaKRTTDfmDIwig7XYUPcCgYEA+zt8WKlL2S41g0s0Y9ZoOX51+BklDmU/PNdDD+PasC1XHqSzsYRsKSdMuuVn2rbAF7RdaJTuQAzV3ZTU4GMPEzAlBh0wOJ7Ok6cuqxIxUPXpPOnEZkz7BpOCng2JEesj48zvUzLrI/jdomxek4E9nd+UOilz6zp7bxZAAkZ1X1MCgYAbtz158Mhy5P4HYpWO20mhjQ1Tov0xBUIHLkE/hk45H47nbfNMhZg7lDv6XU2TmMLkZMXKryvIHJPPFR5N8cFbMiX6FJTqYam8XvOyF7ua1s6izEIT3PrE+KverpPc+dXZ+t/JeSglEBb2UJ6Y5mU1i40YNDaGV4q74EjZzK8a9wKBgQCppyHxPWHa5zl7z+0w/jijmqJPR6uebQCnNTY0JMoo7nVGXDJ8TdA8ei12iDP5ni9Z0pLBbND2r7Q8TaTzYfFsI7bcyhkmIxec3ejF3SMwaN94IuRdsN5TMomh8cH+N6ukyV9T766aPBRu+n9jXIMfeTu/iv6ceoGgIoZXAv1/wQKBgH2FdYT9paG5j0rBOE0/IYKEXOwaZGilkCQp0me3GKrSYDcUi1USSFyJZfmWpkNP18DXifwVsV1pdC9MNPS+ac8Pa+xKSQU9NQHW8aXggAR2dTAf8hkmxIVX3iU+I5EZvWm6HLM3HGu/RFvRFrHzK5Xa9YXnBRxSm+a1sq9A+usF';

  constructor() {}
  encryptData(data: any) {
    let jse = new JSEncrypt();
    jse.setPublicKey(this.publicKey);
    return jse.encrypt(data);
  }

  decryptData(data: any) {
    let jse = new JSEncrypt();
    jse.setPrivateKey(this.publicKey);
    return jse.decrypt(data);
  }
}
