import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RpLoginComponent } from './rp-login/rp-login.component';
import { RpForgotpasswordComponent } from './rp-forgotpassword/rp-forgotpassword.component';
import { RpResetpasswordComponent } from './rp-resetpassword/rp-resetpassword.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RpNewpasswordComponent } from './rp-newpassword/rp-newpassword.component';


@NgModule({
  declarations: [
    RpLoginComponent,
    RpForgotpasswordComponent,
    RpResetpasswordComponent,
    RpNewpasswordComponent,

  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule, SharedModule,
  ]
})
export class AuthModule { }
