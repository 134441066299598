import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RpLoginComponent } from './rp-login/rp-login.component';
import { RpResetpasswordComponent } from './rp-resetpassword/rp-resetpassword.component';
import { RpForgotpasswordComponent } from './rp-forgotpassword/rp-forgotpassword.component';
import { RpNewpasswordComponent } from './rp-newpassword/rp-newpassword.component';

const routes: Routes = [
  // { path: '', component: RpLoginComponent },
  { path: 'login', component: RpLoginComponent },
  { path: 'newpassword', component: RpNewpasswordComponent },
  { path: 'resetPassword', component: RpResetpasswordComponent },
  { path: 'forgotPassword', component: RpForgotpasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
