
import { Component, OnInit, HostListener, ElementRef, AfterViewInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import { AuthenticationService } from '../../services/auth.service';
import { Helper } from '../../helpers/helper';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon, Roles } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-newpassword',
  templateUrl: './rp-newpassword.component.html',
  styleUrl: './rp-newpassword.component.scss',
})
export class RpNewpasswordComponent {
  newpasswordForm!: UntypedFormGroup;
  submitted: any = false;
  fieldTextType!: boolean;
  fieldPwdTextType!: boolean;
  mailId: any;
  resetPwdValidations = Validations.ResetPwdPage;
  currentUser: any;
  Roles = Roles;
  policy: any;
  showPasswordRequirements = false;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthenticationService,
    private sharedService: SharedService,    
    private elementRef: ElementRef
  ) {
    
    this.newpasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/),
        ],
      ],
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.showPasswordRequirements && this.elementRef.nativeElement.contains(targetElement)) {
      this.hidePasswordRequirements();
    }
  }


  ngOnInit() {
    const currentUserData = localStorage.getItem('currentUser');
    
    // Check if currentUserData is not null or empty before parsing
    if (currentUserData) {
      try {
        this.currentUser = JSON.parse(currentUserData);
        this.mailId = this.currentUser?.email;
      } catch (error) {
        console.error('Error parsing currentUser data:', error);
      }
    } else {
      console.log('No current user data found in local storage.');
    }
  
    this.getPwdRegExp();
  }
  pwdRegExp: any;
  regexValidationMsz: any
  
  getPwdRegExp() {
    this.loaderService.setLoading(true);
    this.authService.getPwdRegExp().subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res.statusCode === 200 && res.data.length > 0) {
        const policyData = res.data[0]; // Assuming the first item in the array is the policy
        this.policy = {
          MinLength: policyData.minLength,
          MaxLength: policyData.maxLength,
          RequiresUpperCase: policyData.requiresUpperCase,
          RequiresLowerCase: policyData.requiresLowerCase,
          RequiresSpecialChar: policyData.requiresSpecialChar,
          RequiresNumber: policyData.requiresNumber,
          SpecialCharacters: policyData.specialCharacters
        };
        this.updateFormWithRegexValidator(); // Update form once the regex is fetched
      } else {
        console.log('Failed to retrieve password policy:', res);
      }
    }, error => {
      this.loaderService.setLoading(false);
      console.log('Error fetching password regex:', error);
    });
  }



  updateFormWithRegexValidator() {
    const validators = [
      Validators.required,
      Validators.minLength(this.policy.MinLength),
      Validators.maxLength(this.policy.MaxLength),  // Add MaxLength validation here
      Validators.pattern(this.buildPattern())
    ];
    this.newpasswordForm.get('password')?.setValidators(validators);
    this.newpasswordForm.get('password')?.updateValueAndValidity();


  }



  buildPattern(): string {
    let pattern = '^';

    if (this.policy.RequiresUpperCase) {
      pattern += '(?=.*[A-Z])';
    }
    if (this.policy.RequiresLowerCase) {
      pattern += '(?=.*[a-z])';
    }
    if (this.policy.RequiresNumber) {
      pattern += '(?=.*[0-9])';
    }
    if (this.policy.RequiresSpecialChar) {
      const specialChars = this.policy.SpecialCharacters.split('').map((c: string) => `\\${c}`).join('');
      pattern += `(?=.*[${specialChars}])`;
    }

    pattern += `.{${this.policy.MinLength
      },${this.policy.MaxLength}}$`;

    return pattern;
  }


  
  clickedInside = false;

  togglePasswordRequirements(): void {
    this.showPasswordRequirements = !this.showPasswordRequirements;
    this.clickedInside = true; // Set the flag to true
    setTimeout(() => this.clickedInside = false, 100); // Reset after a short delay
  }
  hasMinLength(password: string): boolean {
    return password.length >= this.policy.MinLength;
  }

  hasUppercaseLetter(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  hasLowercaseLetter(password: string): boolean {
    return /[a-z]/.test(password);
  }

  hasSpecialCharacter(password: string): boolean {
    const specialCharsPattern = new RegExp(`[${this.policy.SpecialCharacters.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}]`);
    return specialCharsPattern.test(password);
  }

  hasNumber(password: string): boolean {
    return /[0-9]/.test(password);
  }


  onPasswordInput(): void {
    this.showPasswordRequirements = true;
  }

  hidePasswordRequirements(): void {
    this.showPasswordRequirements = false;
  }




  get f() {
    return this.newpasswordForm.controls;
  }

  private getLandingPageForRole(role: any): any {
    switch (role) {
      case Roles.SUPERADMIN:
        return '/dashboard';
        break;

      case Roles.ADMIN:
        return '/admindashboard';
        break;

      case Roles.CONSUMER:
        return '/consumerdashboard';
        break;

      case Roles.AGENT:
        return '';
        break;

      default:
        console.error('Unknown role');
        return;
    }
  }


  togglePwdFieldTextType() {
    this.fieldPwdTextType = !this.fieldPwdTextType;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (
      this.newpasswordForm.invalid ||
      this.f['confirmPassword'].value != this.f['password'].value
    ) {
      return;
    }
    this.submitted = false;
    this.loaderService.setLoading(true);
    let payload = {
      password: this.f['password'].value,
      confirmPassword: this.f['confirmPassword'].value,
      email: this.mailId,
    };

    this.authService.newpassword(payload).subscribe({
      next: (res) => {
        // console.log(res);
        if (res.statusCode == 200) {
          this.loaderService.setLoading(false);
          // setTimeout(() => {
          this.sharedService.showMessageDialog(
            'Success',
            res.message,
            SweetAlertIcon.SUCCESS
          );
          this.currentUser = JSON.parse(
            localStorage.getItem('currentUser') ?? ''
          );
          let role = this.currentUser.currentUserRoleDetails.role;
          // debugger;
          const landingPage = this.getLandingPageForRole(role);
          this.router.navigate([landingPage], { replaceUrl: true });
          // }, 3000);
        } else {
          this.sharedService.showMessageDialog(
            'Failure',
            res.message,
            SweetAlertIcon.INFO
          );
        }
      },
      error: (err) => {
        this.sharedService.showMessageDialog(
          'Failure',
          err.error.message,
          SweetAlertIcon.INFO
        );
        this.loaderService.setLoading(false);
        //console.log('err', err);
      },
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
