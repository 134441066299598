
import { Component, OnInit, HostListener, ElementRef, AfterViewInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { Validations } from '../../../shared/constants/validations';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Helper } from '../../helpers/helper';

@Component({
  selector: 'app-rp-resetpassword',
  templateUrl: './rp-resetpassword.component.html',
  styleUrl: './rp-resetpassword.component.scss',
})
export class RpResetpasswordComponent {
  resetForm!: UntypedFormGroup;
  submitted: any = false;
  error: any = '';
  success: any = '';
  loading: any = false;

  otpSuccessMsg: boolean = false;
  otpErrorMsg: boolean = false;

  // set the currenr year
  year: number = new Date().getFullYear();
  fieldTextType!: boolean;
  fieldPwdTextType!: boolean;
  mailId: any;
  respMessage: any;

  resetPwdValidations = Validations.ResetPwdPage;
  policy: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private elementRef: ElementRef
  ) { }

  reset() { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (this.showPasswordRequirements && this.elementRef.nativeElement.contains(targetElement)) {
      this.hidePasswordRequirements();
    }
  }

  getPwdRegExp() {
    this.loaderService.setLoading(true);
    this.authService.getPwdRegExp().subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res.statusCode === 200 && res.data.length > 0) {
        const policyData = res.data[0]; // Assuming the first item in the array is the policy
        this.policy = {
          MinLength: policyData.minLength,
          MaxLength: policyData.maxLength,
          RequiresUpperCase: policyData.requiresUpperCase,
          RequiresLowerCase: policyData.requiresLowerCase,
          RequiresSpecialChar: policyData.requiresSpecialChar,
          RequiresNumber: policyData.requiresNumber,
          SpecialCharacters: policyData.specialCharacters
        };
        this.updateFormWithRegexValidator(); // Update form once the regex is fetched
      } else {
        console.log('Failed to retrieve password policy:', res);
      }
    }, error => {
      this.loaderService.setLoading(false);
      console.log('Error fetching password regex:', error);
    });
  }



  showPasswordRequirements = false;

  onPasswordInput(): void {
    this.showPasswordRequirements = true;
  }

  hidePasswordRequirements(): void {
    this.showPasswordRequirements = false;
  }


  updateFormWithRegexValidator() {
    const validators = [
      Validators.required,
      Validators.minLength(this.policy.MinLength),
      Validators.maxLength(this.policy.MaxLength),  // Add MaxLength validation here
      Validators.pattern(this.buildPattern())
    ];
    this.resetForm.get('password')?.setValidators(validators);
    this.resetForm.get('password')?.updateValueAndValidity();


  }


  
  buildPattern(): string {
    let pattern = '^';

    if (this.policy.RequiresUpperCase) {
      pattern += '(?=.*[A-Z])';
    }
    if (this.policy.RequiresLowerCase) {
      pattern += '(?=.*[a-z])';
    }
    if (this.policy.RequiresNumber) {
      pattern += '(?=.*[0-9])';
    }
    if (this.policy.RequiresSpecialChar) {
      const specialChars = this.policy.SpecialCharacters.split('').map((c: string) => `\\${c}`).join('');
      pattern += `(?=.*[${specialChars}])`;
    }

    pattern += `.{${this.policy.MinLength
      },${this.policy.MaxLength}}$`;

    return pattern;
  }


  clickedInside = false;

  togglePasswordRequirements(): void {
    this.showPasswordRequirements = !this.showPasswordRequirements;
    this.clickedInside = true; // Set the flag to true
    setTimeout(() => this.clickedInside = false, 100); // Reset after a short delay
  }
  hasMinLength(password: string): boolean {
    return password.length >= this.policy.MinLength;
  }

  hasUppercaseLetter(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  hasLowercaseLetter(password: string): boolean {
    return /[a-z]/.test(password);
  }

  hasSpecialCharacter(password: string): boolean {
    const specialCharsPattern = new RegExp(`[${this.policy.SpecialCharacters.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}]`);
    return specialCharsPattern.test(password);
  }

  hasNumber(password: string): boolean {
    return /[0-9]/.test(password);
  }



  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
        ],
      ],
    });

    this.mailId = history?.state?.mailId;
    this.getPwdRegExp()
  }

  sendOtp() {
    this.loaderService.setLoading(true);
    this.authService.forgotPassword(this.mailId).subscribe({
      next: (res) => {
        // console.log(res);
        if (
          res.statusCode == 200 &&
          res.message == 'Password reset link sent successfully.'
        ) {
          this.loaderService.setLoading(false);
          this.sharedService.showMessageDialog(
            'OTP has been sent successfully.',
            '',
            SweetAlertIcon.INFO
          );
        }
      },
      error: (err) => {
        this.sharedService.showMessageDialog(
          'Failure',
          err.error.message,
          SweetAlertIcon.INFO
        );
        this.loaderService.setLoading(false);
        //  console.log('err', err);
      },
    });
  }

  ngAfterViewInit() { }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetForm.controls;
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  togglePwdFieldTextType() {
    this.fieldPwdTextType = !this.fieldPwdTextType;
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;
  
    // Mark all form controls as touched to trigger validation messages
    this.resetForm.markAllAsTouched();
  
    // Stop here if the form is invalid or passwords do not match
    if (this.resetForm.invalid || this.f['confirmPassword'].value !== this.f['password'].value) {
      return;
    }
    this.submitted = false;
    this.loaderService.setLoading(true);
    const payload = {
      password: this.f['password'].value,
      confirmPassword: this.f['confirmPassword'].value,
      email: this.mailId,
      otp: this.f['otp'].value,
    };
  
    this.authService.resetPassword(payload).subscribe({
      next: (res) => {
        if (res.statusCode === 200 && res.message === 'Password reset successful.') {
          this.loaderService.setLoading(false);
          this.router.navigate(['auth/login'], { replaceUrl: true });
        } else {
          this.otpErrorMsg = true;
          this.otpSuccessMsg = false;
          this.respMessage = res;
          this.loaderService.setLoading(false);
        }
      },
      error: (err) => {
        this.sharedService.showMessageDialog(
          'Failure',
          err.error.message,
          SweetAlertIcon.INFO
        );
        this.loaderService.setLoading(false);
      },
    });
  }
  
}
